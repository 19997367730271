<template>
  <v-container>
    <v-layout row wrap justify-space-between class="mb-2">
      <v-flex xs12 sm4 md4 lg4 class="py-1 px-1">
        <v-btn
          :color="$store.state.secondaryColor"
          outlined
          small
          @click="goBack"
        >
          <v-icon small left>mdi-arrow-left</v-icon>
          <span class="caption">{{ $t("back") }}</span>
        </v-btn>
      </v-flex>
      <v-spacer></v-spacer>
    </v-layout>
    <v-layout row wrap justify-space-between class="mb-2">
      <v-flex
        xs12
        sm4
        md4
        lg4
        v-if="!selected && ownersBusinesses.length"
        class="py-1 px-1"
      >
        <v-select
          dense
          solo
          class="caption"
          :label="$t('search business')"
          item-text="businessName"
          item-value="id"
          :items="ownersBusinesses"
          v-model="selectedShop"
          @change="onSelectShop"
        ></v-select>
      </v-flex>
      <v-flex xs12 sm4 md4 lg4 class="text-right py-1 px-1">
        <v-text-field
          v-model="search"
          dense
          solo
          :label="$t('search expense claims')"
        ></v-text-field>
      </v-flex>
      <v-spacer></v-spacer>
      <v-flex xs12 sm4 md2 lg2 class="text-right pa-1">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              color="white"
              @click="getExpenseClaims()"
              ><v-icon>mdi-refresh</v-icon>
            </v-btn>
          </template>
          <span>{{ $t("refresh table") }}</span>
        </v-tooltip>
      </v-flex>
      <v-flex xs12 sm4 md2 lg2 class="text-right pa-1">
        <v-dialog
          ref="dialog"
          v-model="modal"
          :return-value.sync="dateTime"
          persistent
          width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="dateTime"
              append-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
              dense
              in
              solo
            ></v-text-field>
          </template>
          <v-date-picker v-model="dateTime" scrollable>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="modal = false"
              >{{ $t("cancel") }}
            </v-btn>
            <v-btn
              text
              color="primary"
              @click="
                $refs.dialog.save(dateTime);
                dateFilter();
              "
            >
              {{ $t("ok") }}
            </v-btn>
          </v-date-picker>
        </v-dialog>
      </v-flex>
    </v-layout>
    <v-layout row wrap justify-center>
      <v-spacer></v-spacer>
      <v-flex xs12 sm4 md4 lg4 class="py-2 text-right">
        <v-btn
          v-if="userAccess == 1"
          :color="$store.state.primaryColor"
          outlined
          small
          @click="newExpenseClaimOwner()"
        >
          {{ $t("new Expense claim") }}
        </v-btn>
        <v-btn
          v-else
          :color="$store.state.primaryColor"
          outlined
          small
          @click="newExpenseClaimEmployees()"
        >
          {{ $t("new Expense claim") }}
        </v-btn>
      </v-flex>
    </v-layout>

    <v-layout row wrap justify-center>
      <v-flex xs12 sm12 md12 lg12>
        <v-data-table
          :headers="headers"
          :loading="loading"
          :items="filteredExpenseClaims"
          :search="search"
          :sort-by.sync="sortByDate"
          :sort-desc.sync="sortDesc"
          :items-per-page="20"
        >
          <template v-slot:[`item.proofOfPurchase`]="{ item }">
            <v-avatar size="30" @click="viewPicture(item)">
              <v-img :src="item.proofOfPurchase" style="cursor: pointer">
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular
                      width="5"
                      indeterminate
                      :color="$store.state.primaryColor"
                    ></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
            </v-avatar>
          </template>
          <template v-slot:[`item.businessId`]="{ item }">
            {{ getBusinessName(item.businessId) }}
          </template>
          <template v-slot:[`item.dateTime`]="{ item }">
            {{ formatDate(item.dateTime) }}
          </template>
          <template v-slot:[`item.amount`]="{ item }">
            <v-btn small text dark left :color="$store.state.primaryColor">
              {{ currencyFormat(item.amount) }}</v-btn
            >
          </template>
          <template v-slot:[`item.action`]="{ item }">
            <v-icon
              small
              left
              :color="$store.state.primaryColor"
              @click="EditExpenseClaims(item.id)"
              >mdi-pencil</v-icon
            >
            <v-icon
              small
              left
              :color="$store.state.secondaryColor"
              @click="viewExpenseClaimDetails(item.id)"
              >visibility</v-icon
            >
            <v-icon small left color="red" @click="confirmDeletion(item.id)"
              >mdi-delete</v-icon
            >
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>
    <v-dialog v-model="proofOfPurchaseDialog" width="400">
      <v-card dark max-height="440">
        <v-img :src="picture_selected" contain></v-img>
      </v-card>
    </v-dialog>
    <v-dialog v-model="deleteExpenseClaimDialog" persistent max-width="290">
      <v-card>
        <v-card-title class="text-h5">{{
          $t("delete expense claim")
        }}</v-card-title>
        <v-card-text
          >{{
            $t("are you sure you want to delete this expense claim")
          }}?</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            @click="deleteExpenseClaimDialog = false"
          >
            {{ $t("cancel") }}
          </v-btn>
          <v-btn
            color="red darken-1"
            :loading="buttonLoading"
            text
            @click="deleteExpenseClaim()"
          >
            {{ $t("ok") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="snackSuccess"
      top
      center
      color="success lighten-5 success--text text--darken-3"
      :timeout="4000"
    >
      <span>{{ $t("expense claim deleted successfully") }}!</span>
      <template v-slot:action="{ attrs }">
        <v-btn
          v-bind="attrs"
          icon
          color="success darken-3"
          @click="snackSuccess = false"
        >
          <v-icon>close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <v-snackbar
      v-model="snackError"
      top
      center
      color="red lighten-5 error--text text--darken-3"
      :timeout="4000"
    >
      <span>{{ $t("could not delete expense claim. Try again later") }}</span>
      <template v-slot:action="{ attrs }">
        <v-btn
          v-bind="attrs"
          icon
          color="error darken-3"
          @click="snackError = false"
        >
          <v-icon>close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import jwtdecode from "jwt-decode";
import db from "@/plugins/fb";
import { format } from "date-fns";
export default {
  data: () => ({
    loading: true,
    buttonLoading: false,
    search: "",
    expenseClaimId: "",
    proofOfPurchaseDialog: false,
    picture_selected: "",
    deleteExpenseClaimDialog: false,
    userAccess: "",
    modal: false,
    snackSuccess: false,
    snackError: false,
    sortDesc: true,
    sortByDate: "dateTime",
    selectedShop: "",
    filteredExpenseClaims: [],
    expenseClaims: [],
    dateTime: format(new Date(), "yyyy-MM-dd"),
  }),
  computed: {
    selected() {
      return this.$store.getters.getCurrentBusiness;
    },

    ownersBusinessesId() {
      return this.$store.getters.getBusinessId;
    },

    ownersBusinesses() {
      return this.$store.getters.getBusinesses;
    },
    headers() {
      return [
        {
          text: this.$t("proof of purchase"),
          value: "proofOfPurchase",
          class: "blue-grey darken-3 white--text font-weight-regular",
          sortable: false,
        },
        {
          text: this.$t("employee Id"),
          value: "employeeId",
          class: "blue-grey darken-3 white--text font-weight-regular",
          sortable: false,
        },
        {
          text: this.$t("business"),
          value: "businessId",
          class: "blue-grey darken-3 white--text font-weight-regular",
          sortable: false,
        },

        {
          text: this.$t("amount"),
          value: "amount",
          class: "blue-grey darken-3 white--text font-weight-regular",
          sortable: false,
        },
        {
          text: this.$t("date"),
          value: "dateTime",
          class: "blue-grey darken-3 white--text font-weight-regular",
          sortable: false,
        },

        {
          text: this.$t("description"),
          value: "description",
          class: "blue-grey darken-3 white--text font-weight-regular",
          sortable: false,
        },
        {
          text: this.$t("action"),
          value: "action",
          class: "blue-grey darken-3 white--text font-weight-regular",
          sortable: false,
          filterable: false,
        },
      ];
    },
  },
  created() {
    this.getExpenseClaims();
    this.checkAccessLevel();
  },

  methods: {
    checkAccessLevel() {
      //Fetching current user details
      var data = jwtdecode(
        this.$store.state.token,
        this.$store.state.accessKey
      );
      this.userAccess = data.accessLevel.isSuperAdmin
        ? 0
        : data.accessLevel.isOwner
        ? 1
        : data.accessLevel.isEmployee
        ? 2
        : 3;
    },
    fullName(obj) {
      for (let x in obj) {
        return obj[x] + " ";
      }
    },

    getExpenseClaims() {
      this.loading = true;
      this.expenseClaims = [];
      if (this.selected) {
        db.collection("expenseClaims")
          .where("businessId", "==", this.selected)

          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              this.expenseClaims.push({
                id: doc.id,
                ...doc.data(),
              });
            });
            this.filteredExpenseClaims = this.expenseClaims;
            console.log(this.expenseClaims);
            this.loading = false;
          });
      } else {
        db.collection("expenseClaims")
          .where("businessId", "in", this.ownersBusinessesId)

          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              this.expenseClaims.push({
                id: doc.id,
                ...doc.data(),
              });
            });
            this.filteredExpenseClaims = this.expenseClaims;

            this.loading = false;
          });
      }
    },

    onSelectShop() {
      this.loading = true;
      this.expenseClaims = [];
      db.collection("expenseClaims")
        .where("businessId", "==", this.selectedShop)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            this.expenseClaims.push({
              id: doc.id,
              ...doc.data(),
            });
          });

          this.filteredExpenseClaims = this.expenseClaims;
          this.loading = false;
        });
    },

    getBusinessName(id) {
      let busId = this.$store.state.businesses.find((item) => item.id == id);
      let busName = busId.businessName;
      return busName;
    },
    dateFilter() {
      let filtered = this.expenseClaims.filter(
        (item) => format(item.dateTime.toDate(), "yyyy-MM-dd") == this.dateTime
      );
      this.filteredExpenseClaims = filtered;
    },
    viewPicture(data) {
      this.picture_selected = data.proofOfPurchase;
      this.proofOfPurchaseDialog = true;
    },
    newExpenseClaimOwner() {
      this.$router.push({ name: "CreateExpenseClaims-1" });
    },
    newExpenseClaimEmployees(id) {
      this.$router.push({ name: "CreateExpenseClaims-2", params: { id: id } });
    },
    EditExpenseClaims(id) {
      this.$router.push({ name: "EditExpenseClaims", params: { id: id } });
    },
    viewExpenseClaimDetails(id) {
      this.$router.push({ name: "ViewExpenseClaims", params: { id: id } });
    },
    currencyFormat(val) {
      return this.$store.state.currency.symbol + " " + val;
    },
    confirmDeletion(id) {
      this.expenseClaimId = id;
      this.deleteExpenseClaimDialog = true;
    },

    deleteExpenseClaim() {
      this.buttonLoading = true;
      db.collection("expenseClaims")
        .doc(this.expenseClaimId)
        .delete()
        .then(() => {
          this.buttonLoading = false;
          this.deleteExpenseClaimDialog = false;
          this.snackSuccess = true;
          this.getExpenseClaims();
        })
        .catch(() => {
          this.buttonLoading = false;
          this.deleteExpenseClaimDialog = false;
          this.snackError = true;
        });
    },

    formatDate(val) {
      return format(val.toDate(), "dd MMMM, yyyy HH:mm:a");
    },
    goBack() {
      window.history.back();
    },
  },
};
</script>

<style></style>
