var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-layout',{staticClass:"mb-2",attrs:{"row":"","wrap":"","justify-space-between":""}},[_c('v-flex',{staticClass:"py-1 px-1",attrs:{"xs12":"","sm4":"","md4":"","lg4":""}},[_c('v-btn',{attrs:{"color":_vm.$store.state.secondaryColor,"outlined":"","small":""},on:{"click":_vm.goBack}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("mdi-arrow-left")]),_c('span',{staticClass:"caption"},[_vm._v(_vm._s(_vm.$t("back")))])],1)],1),_c('v-spacer')],1),_c('v-layout',{staticClass:"mb-2",attrs:{"row":"","wrap":"","justify-space-between":""}},[(!_vm.selected && _vm.ownersBusinesses.length)?_c('v-flex',{staticClass:"py-1 px-1",attrs:{"xs12":"","sm4":"","md4":"","lg4":""}},[_c('v-select',{staticClass:"caption",attrs:{"dense":"","solo":"","label":_vm.$t('search business'),"item-text":"businessName","item-value":"id","items":_vm.ownersBusinesses},on:{"change":_vm.onSelectShop},model:{value:(_vm.selectedShop),callback:function ($$v) {_vm.selectedShop=$$v},expression:"selectedShop"}})],1):_vm._e(),_c('v-flex',{staticClass:"text-right py-1 px-1",attrs:{"xs12":"","sm4":"","md4":"","lg4":""}},[_c('v-text-field',{attrs:{"dense":"","solo":"","label":_vm.$t('search expense claims')},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-spacer'),_c('v-flex',{staticClass:"text-right pa-1",attrs:{"xs12":"","sm4":"","md2":"","lg2":""}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"white"},on:{"click":function($event){return _vm.getExpenseClaims()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-refresh")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t("refresh table")))])])],1),_c('v-flex',{staticClass:"text-right pa-1",attrs:{"xs12":"","sm4":"","md2":"","lg2":""}},[_c('v-dialog',{ref:"dialog",attrs:{"return-value":_vm.dateTime,"persistent":"","width":"290px"},on:{"update:returnValue":function($event){_vm.dateTime=$event},"update:return-value":function($event){_vm.dateTime=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"append-icon":"mdi-calendar","readonly":"","dense":"","in":"","solo":""},model:{value:(_vm.dateTime),callback:function ($$v) {_vm.dateTime=$$v},expression:"dateTime"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.modal),callback:function ($$v) {_vm.modal=$$v},expression:"modal"}},[_c('v-date-picker',{attrs:{"scrollable":""},model:{value:(_vm.dateTime),callback:function ($$v) {_vm.dateTime=$$v},expression:"dateTime"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.modal = false}}},[_vm._v(_vm._s(_vm.$t("cancel"))+" ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.$refs.dialog.save(_vm.dateTime);
              _vm.dateFilter();}}},[_vm._v(" "+_vm._s(_vm.$t("ok"))+" ")])],1)],1)],1)],1),_c('v-layout',{attrs:{"row":"","wrap":"","justify-center":""}},[_c('v-spacer'),_c('v-flex',{staticClass:"py-2 text-right",attrs:{"xs12":"","sm4":"","md4":"","lg4":""}},[(_vm.userAccess == 1)?_c('v-btn',{attrs:{"color":_vm.$store.state.primaryColor,"outlined":"","small":""},on:{"click":function($event){return _vm.newExpenseClaimOwner()}}},[_vm._v(" "+_vm._s(_vm.$t("new Expense claim"))+" ")]):_c('v-btn',{attrs:{"color":_vm.$store.state.primaryColor,"outlined":"","small":""},on:{"click":function($event){return _vm.newExpenseClaimEmployees()}}},[_vm._v(" "+_vm._s(_vm.$t("new Expense claim"))+" ")])],1)],1),_c('v-layout',{attrs:{"row":"","wrap":"","justify-center":""}},[_c('v-flex',{attrs:{"xs12":"","sm12":"","md12":"","lg12":""}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"loading":_vm.loading,"items":_vm.filteredExpenseClaims,"search":_vm.search,"sort-by":_vm.sortByDate,"sort-desc":_vm.sortDesc,"items-per-page":20},on:{"update:sortBy":function($event){_vm.sortByDate=$event},"update:sort-by":function($event){_vm.sortByDate=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"item.proofOfPurchase",fn:function(ref){
              var item = ref.item;
return [_c('v-avatar',{attrs:{"size":"30"},on:{"click":function($event){return _vm.viewPicture(item)}}},[_c('v-img',{staticStyle:{"cursor":"pointer"},attrs:{"src":item.proofOfPurchase},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-row',{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"width":"5","indeterminate":"","color":_vm.$store.state.primaryColor}})],1)]},proxy:true}],null,true)})],1)]}},{key:"item.businessId",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getBusinessName(item.businessId))+" ")]}},{key:"item.dateTime",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.dateTime))+" ")]}},{key:"item.amount",fn:function(ref){
              var item = ref.item;
return [_c('v-btn',{attrs:{"small":"","text":"","dark":"","left":"","color":_vm.$store.state.primaryColor}},[_vm._v(" "+_vm._s(_vm.currencyFormat(item.amount)))])]}},{key:"item.action",fn:function(ref){
              var item = ref.item;
return [_c('v-icon',{attrs:{"small":"","left":"","color":_vm.$store.state.primaryColor},on:{"click":function($event){return _vm.EditExpenseClaims(item.id)}}},[_vm._v("mdi-pencil")]),_c('v-icon',{attrs:{"small":"","left":"","color":_vm.$store.state.secondaryColor},on:{"click":function($event){return _vm.viewExpenseClaimDetails(item.id)}}},[_vm._v("visibility")]),_c('v-icon',{attrs:{"small":"","left":"","color":"red"},on:{"click":function($event){return _vm.confirmDeletion(item.id)}}},[_vm._v("mdi-delete")])]}}],null,true)})],1)],1),_c('v-dialog',{attrs:{"width":"400"},model:{value:(_vm.proofOfPurchaseDialog),callback:function ($$v) {_vm.proofOfPurchaseDialog=$$v},expression:"proofOfPurchaseDialog"}},[_c('v-card',{attrs:{"dark":"","max-height":"440"}},[_c('v-img',{attrs:{"src":_vm.picture_selected,"contain":""}})],1)],1),_c('v-dialog',{attrs:{"persistent":"","max-width":"290"},model:{value:(_vm.deleteExpenseClaimDialog),callback:function ($$v) {_vm.deleteExpenseClaimDialog=$$v},expression:"deleteExpenseClaimDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v(_vm._s(_vm.$t("delete expense claim")))]),_c('v-card-text',[_vm._v(_vm._s(_vm.$t("are you sure you want to delete this expense claim"))+"?")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"green darken-1","text":""},on:{"click":function($event){_vm.deleteExpenseClaimDialog = false}}},[_vm._v(" "+_vm._s(_vm.$t("cancel"))+" ")]),_c('v-btn',{attrs:{"color":"red darken-1","loading":_vm.buttonLoading,"text":""},on:{"click":function($event){return _vm.deleteExpenseClaim()}}},[_vm._v(" "+_vm._s(_vm.$t("ok"))+" ")])],1)],1)],1),_c('v-snackbar',{attrs:{"top":"","center":"","color":"success lighten-5 success--text text--darken-3","timeout":4000},scopedSlots:_vm._u([{key:"action",fn:function(ref){
              var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"icon":"","color":"success darken-3"},on:{"click":function($event){_vm.snackSuccess = false}}},'v-btn',attrs,false),[_c('v-icon',[_vm._v("close")])],1)]}}]),model:{value:(_vm.snackSuccess),callback:function ($$v) {_vm.snackSuccess=$$v},expression:"snackSuccess"}},[_c('span',[_vm._v(_vm._s(_vm.$t("expense claim deleted successfully"))+"!")])]),_c('v-snackbar',{attrs:{"top":"","center":"","color":"red lighten-5 error--text text--darken-3","timeout":4000},scopedSlots:_vm._u([{key:"action",fn:function(ref){
              var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"icon":"","color":"error darken-3"},on:{"click":function($event){_vm.snackError = false}}},'v-btn',attrs,false),[_c('v-icon',[_vm._v("close")])],1)]}}]),model:{value:(_vm.snackError),callback:function ($$v) {_vm.snackError=$$v},expression:"snackError"}},[_c('span',[_vm._v(_vm._s(_vm.$t("could not delete expense claim. Try again later")))])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }